/**
 * @Description Container for Fasat Tab
 * @FileName fasatTab.js
 * @Author SAMAPTI TALUKDAR-talusam
 * @CreatedOn 04 March, 2021 05:38:22
 * @IssueID 623
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, TabPanel } from '@manulife/mux';
import './fasatTab.scss';
import './fasatTabs.scss';
import './fasatTabStyle.scss';

const FasatTab = (props) => {
  const { tabContent } = props;
  return (
    <div className="fasatTab">
      <Tabs {...props}>
        {props && tabContent.map((item) => (
          <TabPanel>{item.content}</TabPanel>
        ))}
      </Tabs>
    </div>
  );
};

FasatTab.propTypes = {
  tabContent: PropTypes.arrayOf(PropTypes.shape()),
};

FasatTab.defaultProps = {
  tabContent: [],
};

export default FasatTab;
