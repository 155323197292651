/**
 * @Description FasatColumnOrder Action File
 * @FileName Action.js
 * @Author ABHISEK KUNDU-kundabh
 * @CreatedOn 22 March, 2021 02:59:30
 * @IssueID 673
 */
import PropTypes from 'prop-types';
import React from 'react';

import classNames from './util/classNames';
import iconsShape from './shapes/iconsShape';
import languageShape from './shapes/languageShape';
import capitalizeFirstLetter from './util/capitalizeFirstLetter';

class Action extends React.Component {
  /**
   * @returns {void}
   */
  constructor() {
    super();

    this.onClick = this.onClick.bind(this);
  }

  /**
   * @returns {void}
   */
  onClick() {
    const { direction, isMoveAll, onClick } = this.props;

    onClick({
      direction,
      isMoveAll,
    });
  }

  /**
   * @returns {string}
   */
  getId() {
    const { id, direction, isMoveAll } = this.props;

    return `${id}-move${isMoveAll ? '-all' : ''}-${direction}`;
  }

  /**
   * @returns {string}
   */
  getActionKey() {
    const { direction, isMoveAll } = this.props;

    return `move${isMoveAll ? 'All' : ''}${capitalizeFirstLetter(direction)}`;
  }

  /**
   * @returns {string}
   */
  getLabel() {
    const { lang } = this.props;

    return lang[this.getActionKey()];
  }

  /**
   * @returns {*}
   */
  renderIcons() {
    const { icons } = this.props;

    return icons[this.getActionKey()];
  }

  /**
   * @returns {React.Component}
   */
  render() {
    const { direction, disabled, isMoveAll, labels } = this.props;
    const id = this.getId();
    const label = labels.button[direction];
    const className = classNames({
      'rdl-move': true,
      'rdl-move-all': isMoveAll,
      [`rdl-move-${direction}`]: true,
    });

    return (
      <button
        aria-label={label}
        className={className}
        disabled={disabled}
        id={id}
        type="button"
        onClick={this.onClick}
      >
        {this.renderIcons()}
      </button>
    );
  }
}

Action.propTypes = {
  direction: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  icons: iconsShape.isRequired,
  id: PropTypes.string.isRequired,
  lang: languageShape.isRequired,
  onClick: PropTypes.func.isRequired,

  isMoveAll: PropTypes.bool,
  labels: PropTypes.shape({
    button: PropTypes.shape({
      left: PropTypes.string,
      right: PropTypes.string,
      top: PropTypes.string,
      up: PropTypes.string,
      down: PropTypes.string,
      bottom: PropTypes.string,
    }),
  }),
};

Action.defaultProps = {
  isMoveAll: false,
  labels: {},
};

export default Action;
