/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/**
 * @Description Fasat Grid Utils for Fasat Grid Action Body Generator
 * @FileName fasatGridUtil.js
 * @Author ABHISEK KUNDU-kundabh
 * @CreatedOn 10 February, 2021 09:52:57
 * @IssueID -323
 */
import React from 'react';
import { colors } from '@manulife/mux';
import { Chart, DateRange, Delete, Edit, Play1, Eye1, Download,
  Duplicate, ButtonXOutlined, Comment } from '@manulife/mux-cds-icons';
import { useSelector } from 'react-redux';
import { Logger } from '../../util';
import FasatActionBtn from '../fasatActionBtn/fasatActionBtn';
import FasatLink from '../fasatLink/fasatLink';
import { ACTION_TYPE } from './fasatGridMetadata';
import FasatExpandableParagraph from '../fasatExpandableParagraph/fasatExpandableParagraph';

const dateBodyGenerator = (dateFormat) => {
  function dateBody(rowData, columnData) {
    Logger.info(`Going to fetch data...., ${rowData}, ${columnData}, ${dateFormat}`);
    return rowData[columnData.field];
  }
  return dateBody;
};

const rowDataThree = 3;
const sharedCountThree = 3;

const sortByColumnOrder = (i1, i2) => {
  if (i1 && i2) {
    return i1.order - i2.order;
  }
  return 0;
};

/**
 * Method to show data in comma separated manner
 * @returns comma separated string
 */
const commaSeparatedBodyGenerator = () => (rowData) => {
  const { cell } = rowData;
  if (cell.row.original.sharedWith) {
    const sharedCount = cell.row.original.sharedWith.length;
    const collapsedContent = () => (
      <span>
        {cell.row.original.sharedWith.slice(0, rowDataThree).join(', ')}
        <span>,</span>
      </span>
    );

    const expandedContent = () => (
      <span>
        {cell.row.original.sharedWith.slice(rowDataThree, sharedCount).join(', ')}
      </span>
    );

    return (
      <>
        {sharedCount > sharedCountThree && (
        <FasatExpandableParagraph
          collapsedContent={collapsedContent()}
          expandedContent={expandedContent()}
          showLessButtonAriaLabel="Show less"
          showMoreButtonAriaLabel="Show more"
        />
        )}
        {sharedCount > 1 && sharedCount <= sharedCountThree && (
        <span>{cell.row.original.sharedWith.join(', ')}</span>
        )}
        {sharedCount === 1 && (
        <span>{cell.row.original.sharedWith[0]}</span>
        )}
      </>
    );
  }
  return (<></>);
};

const linkBodyGenerator = (actions, dataFieldKey, link) => (rowData) => {
  const { cell } = rowData;
  if (actions && actions.length > 0) {
    return (
      <>
        {actions
          .sort((i1, i2) => sortByColumnOrder(i1, i2))
          .map((action) => (
            <FasatLink
              to={link}
              onClick={() => action.actionHandler(rowData)}
            >
              {cell.row.original[dataFieldKey]}
            </FasatLink>
          ))}
      </>
    );
  }
  return null;
};

export const generateIconPartOne = (icon) => {
  if (ACTION_TYPE.EDIT === icon) {
    return <Edit color={colors.m_green} style={{ width: '18px', height: '18px' }} />;
  }
  if (ACTION_TYPE.SPLIT === icon) {
    return <DateRange color={colors.m_green} style={{ width: '17px', height: '20px' }} />;
  }
  if (ACTION_TYPE.DELETE === icon) {
    return <Delete color={colors.m_green} style={{ width: '16px', height: '19px' }} />;
  }
  if (ACTION_TYPE.LOAD === icon) {
    return <Play1 color={colors.m_green} style={{ width: '20px', height: '20px' }} />;
  }
  if (ACTION_TYPE.TREE === icon) {
    return <Chart color={colors.m_green} style={{ width: '20px', height: '20px' }} />;
  }
  if (ACTION_TYPE.EDITHOVER === icon) {
    return <Edit color={colors.dark_2_green} style={{ width: '18px', height: '18px' }} />;
  }
  if (ACTION_TYPE.FORCECOMPLETE === icon) {
    return <ButtonXOutlined color={colors.m_green} style={{ width: '18px', height: '18px' }} />;
  }
  if (ACTION_TYPE.FORCECOMPLETEHOVER === icon) {
    return <ButtonXOutlined color={colors.dark_2_green} style={{ width: '18px', height: '18px' }} />;
  }
  return null;
};

const iconPart2SpltOne = (icon) => {
  switch (icon) {
    case ACTION_TYPE.SPLITHOVER:
      return <DateRange color={colors.dark_2_green} style={{ width: '17px', height: '20px' }} />;
    case ACTION_TYPE.DELETEHOVER:
      return <Delete color={colors.dark_2_green} style={{ width: '16px', height: '19px' }} />;
    case ACTION_TYPE.LOADHOVER:
      return <Play1 color={colors.dark_2_green} style={{ width: '20px', height: '20px' }} />;
    case ACTION_TYPE.TREEHOVER:
      return <Chart color={colors.dark_2_green} style={{ width: '20px', height: '20px' }} />;
    case ACTION_TYPE.VIEW:
      return <Eye1 color={colors.m_green} style={{ width: '18px', height: '18px' }} />;
    case ACTION_TYPE.VIEWHOVER:
      return <Eye1 color={colors.dark_2_green} style={{ width: '18px', height: '18px' }} />;
    default:
      return null;
  }
};

const iconPart2SpltTwo = (icon) => {
  switch (icon) {
    case ACTION_TYPE.DOWNLOAD:
      return <Download color={colors.m_green} style={{ width: '18px', height: '18px' }} />;
    case ACTION_TYPE.DOWNLOADHOVER:
      return <Download color={colors.dark_2_green} style={{ width: '18px', height: '18px' }} />;
    case ACTION_TYPE.DUPLICATE:
      return <Duplicate color={colors.m_green} style={{ width: '18px', height: '18px' }} />;
    case ACTION_TYPE.DUPLICATEHOVER:
      return <Duplicate color={colors.dark_2_green} style={{ width: '18px', height: '18px' }} />;
    case ACTION_TYPE.COMMENT:
      return <Comment color={colors.m_green} style={{ width: '18px', height: '18px' }} />;
    case ACTION_TYPE.COMMENTHOVER:
      return <Comment color={colors.dark_2_green} style={{ width: '18px', height: '18px' }} />;
    default:
      throw new Error(`Invalid action type ${this.icon}`);
  }
};

export const generateIconPartTwo = (icon) => {
  const iconSel = iconPart2SpltOne(icon);
  if (iconSel) {
    return iconSel;
  }
  return iconPart2SpltTwo(icon);
};

export const generateIcon = (icon) => {
  const iconSelPartOne = generateIconPartOne(icon);
  if (iconSelPartOne) {
    return iconSelPartOne;
  }
  return generateIconPartTwo(icon);
};

export const getDisableLogic1 = ({ pdDisableLogic, deleteDisableLogic }) => (pdDisableLogic || deleteDisableLogic);
export const getDisableLogic2 = ({ btchDis, splitDisableLogic,
  dpDisableLogic }) => (btchDis || splitDisableLogic || dpDisableLogic);

const actionBtnDisable = ({ pdDisableLogic, deleteDisableLogic, btchDis, splitDisableLogic,
  dpDisableLogic }) => (getDisableLogic1({ pdDisableLogic, deleteDisableLogic })
   || getDisableLogic2({ btchDis, splitDisableLogic, dpDisableLogic }));

const setAriaLabel = (action) => (action.ariaLabel || action.name);

const fasatActBtn = (action, rowData, conditionalDisable, columnData, params) => {
  const { isEnableChecker, ownerDisable, pdDisableLogic, deleteDisableLogic, btchDis, dpDisableLogic,
    splitDisableLogic } = params;
  const isEnableCheckerFlag = (isEnableChecker && !isEnableChecker(action, rowData, columnData));
  const diableFlag = (ownerDisable || conditionalDisable);
  return (
    <FasatActionBtn
      hoverIcon={generateIcon(action.hoverIcon)}
      key={action.id}
      icon={generateIcon(action.icon)}
      onClick={() => action.actionHandler(rowData, columnData)}
      {...(isEnableCheckerFlag || (diableFlag || actionBtnDisable({ pdDisableLogic,
        deleteDisableLogic,
        btchDis,
        splitDisableLogic,
        dpDisableLogic })
      )
        ? { disabled: true }
        : {})}
      label={action.name}
      ariaLabel={setAriaLabel(action)}
    />
  );
};

const disableLogicConfig = (action, cell) => ((action.pdDisable >= new Date(cell.row.original.finishDate))
|| (action.pdDisable >= new Date(cell.row.original.startDate)
&& action.pdDisable <= new Date(cell.row.original.finishDate)));

const userDisable = ({ cell, loggedInUser, action }) => (cell.row.original.owner
  && cell.row.original.owner !== loggedInUser
  && action.iconDisabled);

const validEquality = (param1, param2) => (param1 === param2);

const rowGenarater = ({ cell, loggedInUser, action }) => {
  let conditionalDisable = false;
  let pdDisableLogic = false;
  let deleteDisableLogic = false;
  let splitDisableLogic = false;
  let btchDis = false;
  let dpDisableLogic = false;
  const ownerDisable = userDisable({ cell, loggedInUser, action });
  if (action.isDisable) {
    conditionalDisable = validEquality(cell.row.original[action.isDisable.key], action.isDisable.value);
  }
  if (action.pdDisable) {
    pdDisableLogic = disableLogicConfig(action, cell);
  }
  if (action.deleteDisable) {
    deleteDisableLogic = validEquality(cell.row.original.periodId, action.deleteDisable.totalRows);
  }
  if (action.splitDisable) {
    const d1 = new Date(cell.row.original.startDate);
    const d2 = new Date(cell.row.original.finishDate);
    splitDisableLogic = validEquality(d1.toDateString(), d2.toDateString());
  }
  if (action.batchDisable) {
    btchDis = !validEquality(cell.row.original.lastUpdatedStatusList, action.batchlastUpdatedStatus);
  }
  if (action.disableDP) {
    dpDisableLogic = true;
  }
  return { ownerDisable,
    conditionalDisable,
    pdDisableLogic,
    deleteDisableLogic,
    dpDisableLogic,
    splitDisableLogic,
    btchDis };
};

const actionBodyGenerator = (actions, isEnableChecker) => {
  const loggedUser = useSelector(
    ({ ApplicationStateRBAC }) => ApplicationStateRBAC && ApplicationStateRBAC.loggedUser,
  );
  const loggedInUser = loggedUser && loggedUser.UserId;
  const hasUserClaim = (action) => {
    const userClaim = useSelector(
      ({ ApplicationStateRBAC }) => ApplicationStateRBAC.rbacData,
    );
    if (action.aclkey) {
      const aclObject = userClaim?.access.find((i) => i.resource === action.aclkey);
      if (aclObject) {
        if (aclObject.permission === 'ENABLED') {
          return true;
        }
        return false;
      }
      return false;
    }
    return true;
  };
  return (rowData, columnData = null) => {
    const { cell } = rowData;
    if (actions && actions.length > 0) {
      return (
        <>
          {actions
            .sort((i1, i2) => sortByColumnOrder(i1, i2))
            .map((action) => {
              const { ownerDisable, conditionalDisable, pdDisableLogic,
                deleteDisableLogic, splitDisableLogic, btchDis, dpDisableLogic } = rowGenarater({ cell, loggedInUser, action });
              const params = { columnData,
                isEnableChecker,
                ownerDisable,
                pdDisableLogic,
                deleteDisableLogic,
                splitDisableLogic,
                btchDis,
                dpDisableLogic };
              return (hasUserClaim(action) && fasatActBtn(action, rowData, conditionalDisable, columnData, params));
            })}
        </>
      );
    }
    return null;
  };
};

const actionBodyGeneratorRowLevelVisibility = (actions, isEnableChecker, checkRowLevelVisibility = () => true) => {
  const loggedUser = useSelector(
    ({ ApplicationStateRBAC }) => ApplicationStateRBAC && ApplicationStateRBAC.loggedUser,
  );
  const loggedInUser = loggedUser && loggedUser.UserId;
  const hasUserClaim = (action) => {
    const userClaim = useSelector(
      ({ ApplicationStateRBAC }) => ApplicationStateRBAC.rbacData,
    );
    if (action.aclkey) {
      const aclObject = userClaim?.access.find((i) => i.resource === action.aclkey);
      if (aclObject) {
        return (aclObject.permission === 'ENABLED');
      }
    }
    return true;
  };
  return (rowData, columnData = null) => {
    const { cell } = rowData;
    if (actions && actions.length > 0) {
      return (
        <>
          {actions
            .sort((i1, i2) => sortByColumnOrder(i1, i2))
            .map((action) => {
              const { ownerDisable, conditionalDisable, pdDisableLogic,
                deleteDisableLogic, splitDisableLogic, btchDis, dpDisableLogic } = rowGenarater({ cell, loggedInUser, action });
              const params = { columnData,
                isEnableChecker,
                ownerDisable,
                pdDisableLogic,
                deleteDisableLogic,
                splitDisableLogic,
                btchDis,
                dpDisableLogic };
              return (checkRowLevelVisibility(rowData) && hasUserClaim(action) && fasatActBtn(action, rowData, conditionalDisable, columnData, params));
            })}
        </>
      );
    }
    return null;
  };
};

export { actionBodyGenerator, dateBodyGenerator, linkBodyGenerator
  , commaSeparatedBodyGenerator, actionBodyGeneratorRowLevelVisibility };
