import { globalConstants, staticCommonLabelKeys } from '../moduleConstants';
import { COLUMN_TYPE } from './utility';

const defaultColumnValue = ({ field, label }) => ({
  FilterDefination: null,
  actions: [],
  body: null,
  dataField: field,
  disableFilter: false,
  disableSortBy: false,
  header: label,
  id: field,
  isAction: false,
  visibility: field,
  width: 180,
  sortAscending: field,
  aclKey: 'aclKey',
  lookupKey: '',
});

export const defaultColumnValueText = ({ field, label, lookupKey, action }) => ({
  ...defaultColumnValue({ field, label }),
  type: COLUMN_TYPE.TEXT,
  columnType: action || 'text',
  lookupKey: lookupKey || '',
});

export const defaultColumnValueNumber = ({ field, label, lookupKey, action }) => ({
  ...defaultColumnValue({ field, label }),
  columnType: action || 'text',
  type: COLUMN_TYPE.NUMBER,
  lookupKey: lookupKey || '',
});
export const defaultColumnValueDate = ({ field, label }) => ({
  ...defaultColumnValue({ field, label }),
  headerUnbold: staticCommonLabelKeys.COMMON_DATE_FORMAT_MM_DD_YYYY,
  columnType: 'date',
  type: COLUMN_TYPE.DATE,
});

export const getTableData = ({ listData }) => {
  const currentPage = listData?.page || 1;
  const totalRows = listData?.totalRows || 0;
  const rowCount = listData && listData.data ? listData.data.length : 0;
  const gridPageSize = listData?.pageSize || globalConstants.pageSizeTen;
  return { currentPage, totalRows, rowCount, gridPageSize };
};

export const pageGridSize = (details, listData, setGridPageSize) => {
  if (details && !listData) {
    setGridPageSize(details.defaultPageSize);
  }
};

export const setActiveExport = (rowCount, setactivateExportButton, activateDataGrid) => {
  if (rowCount > 0 && activateDataGrid) {
    setactivateExportButton(true);
  } else {
    setactivateExportButton(false);
  }
};

export const columnSettings = (listColumnOrder, setColumnOrder) => {
  if (listColumnOrder) {
    setColumnOrder(listColumnOrder);
  }
};

export const onColumnResizeHandler = (columnResizing) => columnResizing;
export const onSortByHandler = (sortBy) => sortBy;
export const onFilterHandler = (filters) => filters;
export const onPageChangeHandler = (currPage) => currPage;

export const commaSeperatedNumber = (number, locale) => {
  if (locale === 'E' || locale === 'en') {
    return new Intl.NumberFormat('en-US', { }).format(number);
  }
  return new Intl.NumberFormat('fr-FR', { }).format(number);
};

export const COLUMN_TYPE_DYNAMIC = {
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  DATE: 'DATE',
  DATETIME: 'DATETIME',
  BOOLEAN: 'BOOLEAN',
  CURRENCY: 'CURRENCY',
};
