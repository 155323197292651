/**
 * @Description This is fasatSecuritySettings container page
 * @FileName fasatSecuritySettings.js
 * @Author SAMAPTI TALUKDAR-talusam
 * @CreatedOn 27 January, 2021 11:15:07
 * @IssueID CDTFASATMOD-502
 */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../../index.scss';
import './fasatHTMLgrid.scss';
import PropTypes, { string } from 'prop-types';
import FasatCheckbox from '../fasatCheckbox/fasatCheckbox';
import { NoData } from '../../libs/fasatSharedComponent/customGrid/styledComponents';
import { staticCommonLabelKeys } from '../../moduleConstants';
import { currencyFormat } from '../../util';
import { getCurrentLocale } from '../../i18n';

export const getAmount = (amt) => {
  const amtVal = amt || '0.00 ';
  const decimalPlace = '2';
  const localeGet = getCurrentLocale();
  const curr1 = currencyFormat(amtVal.toString(), localeGet, decimalPlace, 'withSymbol');
  return curr1;
};

export const getPercent = (amt) => {
  const percentVal = '2';
  return amt !== null ? `${amt.toFixed(percentVal)}%` : '';
};

const theadRow = ({ header, matTable, selectAll, headerChkboxAreaText }) => (
  <thead>
    {matTable ? (
      <tr>
        <th>
          <FasatCheckbox id="selectAllChkBox" className="selection-all" aria-label={headerChkboxAreaText} onHandleChange={selectAll} />
        </th>

        {header.map((th) => (
          <th>{th}</th>
        ))}
      </tr>
    ) : (
      <tr className="activity">
        {header.map((th) => (
          <th>{th}</th>
        ))}
      </tr>
    )}
  </thead>
);

export const getValue = (compVal, showData) => {
  let dataVal;
  if (compVal === 'DR') {
    dataVal = getAmount(showData);
  } else {
    dataVal = getPercent(showData);
  }
  return dataVal;
};

const getTables = ({ data, matTable, rowSelection, negativeRateData, jobStatusData }) => {
  let val;
  if (matTable) {
    val = data.map((tr, key) => (
      <tr key={tr.key}>
        <td>
          <FasatCheckbox
            className="selection-box"
            id={tr.key}
            name={`matCheckbox-${key}`}
            onHandleChange={(status) => rowSelection(status, tr)}
            title={`matCheckbox-${key}`}
          />
        </td>
        <td>{tr.level}</td>
        <td>{tr.type}</td>
        <td>{tr.orgCode}</td>
        <td>{tr.name}</td>
      </tr>
    ));
  }
  if (negativeRateData) {
    val = data.map((tr) => (
      <tr key={tr.key}>
        <td>{tr.agentCode}</td>
        <td>{tr.rateMasterId}</td>
        <td>{tr.rateDetailId}</td>
        <td>
          {getValue(tr.rateBasis, tr.premiumRate)}

        </td>
        <td>{tr.rateBasis}</td>
        <td>{tr.rateDuration}</td>
        <td>{tr.rateTerm}</td>
        <td>{tr.rateMethod}</td>
        <td>
          {getValue(tr.rateBasis, tr.chargeBackRate)}

        </td>
        <td>{tr.chargeBackRateBasis}</td>
        <td>{tr.chargeBackRateMethod}</td>

      </tr>
    ));
  }
  if (jobStatusData) {
    val = data.map((tr) => (
      <tr key={tr.key}>
        <td>{tr}</td>
      </tr>
    ));
  }
  return val;
};

const tbodyRow = ({ data, matTable, rowSelection, header, t, negativeRateData, jobStatusData }) => (
  <tbody>
    {data.length ? (
      getTables({ data, matTable, rowSelection, negativeRateData, jobStatusData })) : (
        <tr>
          <td colSpan={header.length + 1} className="withNoRow">
            <NoData>{t(staticCommonLabelKeys.COMMON_MSG_NO_DATA_AVAILABLE)}</NoData>
          </td>
        </tr>
    )}
  </tbody>
);
const FasatHTMLgrid = (props) => {
  const [t] = useTranslation();
  const { data, header, selectedValueRef, getSelectedRows, tableCaption, checkBoxFlag, headerChkboxAreaText,
    negativeRateData, jobStatusData } = props;
  const matTable = checkBoxFlag;
  const selectAll = (status) => {
    selectedValueRef.current = status ? data.map((item) => ({ original: item })) : [];
    const rowCheckBoxes = document.getElementsByClassName('selection-box');
    for (let item = 0; item < rowCheckBoxes.length; item += 1) {
      const element = rowCheckBoxes[item];
      element.children[0].childNodes[0].checked = status;
    }
    getSelectedRows(selectedValueRef.current);
  };
  const rowSelection = (status, value) => {
    const selectAllCheckBox = document.getElementsByClassName('selection-all')[0].children[0].childNodes[0];
    let prevSelection = selectedValueRef.current || [];
    if (status) {
      prevSelection.push({ original: value });
    } else {
      prevSelection = prevSelection.filter(
        (item) => !(item.original.orgCode === value.orgCode && item.original.orgLevel === value.orgLevel),
      );
    }
    selectedValueRef.current = prevSelection;
    getSelectedRows(selectedValueRef.current);
    if (selectedValueRef.current.length === data.length && data.length > 1) {
      selectAllCheckBox.checked = true;
      return;
    }
    selectAllCheckBox.checked = false;
  };
  useEffect(() => getSelectedRows(selectedValueRef.current), []);

  return (
    <table className="fasatHTMLgrid" data-testid="TableComponent" cellPadding="0" cellSpacing="0">
      <caption>{tableCaption}</caption>
      {theadRow({ header, matTable, selectAll, headerChkboxAreaText })}
      {tbodyRow({ data, matTable, rowSelection, header, t, negativeRateData, jobStatusData })}
    </table>
  );
};

FasatHTMLgrid.propTypes = {
  header: PropTypes.shape(),
  data: PropTypes.shape(),
  checkBoxFlag: PropTypes.bool,
  selectedValueRef: PropTypes.shape(),
  getSelectedRows: PropTypes.shape(),
  tableCaption: string,
  headerChkboxAreaText: string,
  negativeRateData: PropTypes.bool,
  jobStatusData: PropTypes.bool,
};

FasatHTMLgrid.defaultProps = {
  header: [],
  data: [],
  checkBoxFlag: true,
  selectedValueRef: {},
  getSelectedRows: () => null,
  tableCaption: '',
  headerChkboxAreaText: '',
  negativeRateData: false,
  jobStatusData: false,
};

export default FasatHTMLgrid;
