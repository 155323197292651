/**
 * @Description This is FASAT Quick Action Notification component
 * @FileName fasatToast.js
 * @Author Imroz Dawar-dawarim
 * @CreatedOn 9 February, 2021 15:57:14
 * @IssueID CDTFASATMOD-314
 */

import { Icon, MessageCard } from '@manulife/mux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import './fasatToast.scss';
import { useEffect, useRef } from 'react';

/**
 * This would close the existing fasatQuickActionNotification component
 * has not been exported.
 * @function useEnterAlerter : used to close toast if enter/esc/spacebar is pressed
 *
 */
const keyCodeTwentySeven = 27;
const keyCodeThirtyTwo = 32;
const useEnterAlerter = (ref) => {
  useEffect(() => {
    const listener = (event) => {
      if (
        event.keyCode === keyCodeTwentySeven
        || event.code === 'Enter'
        || event.keyCode === keyCodeThirtyTwo
        || event.code === 'NumpadEnter'
      ) {
        toast.dismiss();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [ref]);
};

/**
 * This would return the fasat specific toast message styling. Hence this component
 * has not been exported.
 * @param {String} message : message which would be shown in the toast
 * @param {string} variantType : used to change the MUX component imported
 */
export const FasatToastContent = ({ message, variantType }) => {
  const toastRef = useRef(0);

  useEnterAlerter(toastRef);
  return (
    <div className="messageContainer" ref={toastRef}>
      <span className="iconContainer">
        <MessageCard
          closeIcon={(
            <Icon
              alt=""
              ariaHidden
              cdsName=""
              color="inherit"
              fill="none"
              fontsize="21px"
              height={null}
              manulifeName="close"
              name=""
              opacity={1}
              path=""
              secondaryColor="none"
              width="70%"
            />
          )}
          closeIconAriaLabel=""
          hasCloseButton
          titleText="Important Information"
          variant={variantType}
        >
          {typeof message === 'object' && message.map((msg) => <div>{msg}</div>)}
          {typeof message === 'string' && <div>{message}</div>}
        </MessageCard>
      </span>
    </div>
  );
};
FasatToastContent.propTypes = {
  variantType: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

/**
 * This would show the message via FasatToastContainer.
 * Please note that you have to include FasatToastContainer
 * to make this function work.
 * @param {String} message - message which needs to be shown as toast message
 * @param {*} option - options to configure the toast
 */
const FasatShowToastMessage = (
  message,
  variantType,
  option = {
    position: 'bottom-center',
    autoClose: false,
    hideProgressBar: true,
    closeOnClick: true,
    closeButton: false,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    className: 'fasatQuickActionNotification',
  },
) => {
  toast(<FasatToastContent message={message} variantType={variantType} />, option);
};

export default FasatShowToastMessage;
